import Calculator from './containers/Calculator';
import styles from './App.module.scss';

const App = () => {
	return(
		<div className={styles.wrapper}>
			<Calculator />
		</div>
	)
}

export default App;