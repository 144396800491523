import styles from './Inputs.module.scss';
import { IoPersonOutline, IoPeopleOutline} from 'react-icons/io5'
import { BiInfoCircle } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip';

interface IProps { 
    users: number;
    calls: number;
    onChangeHandler: (target: string, value: string) => void
}

const Inputs = ({ users, calls, onChangeHandler }: IProps) => {

    return(
        <section className={styles.inputs}>
            <div>
            </div>
            <div className={styles.cpsoffering}>
                <h3>Direct Plan</h3>
            </div>
            <div className={styles.cpsoffering}>
                <h3>Connect Plan</h3>
            </div>
            <div className={styles.msoffering}>
                <h2>Microsoft Calling Plan</h2>
            </div>
            <div className={styles.inputs__input}>
                <h2 className={styles.inputIcons}>
                    <IoPersonOutline />
                </h2>
                <div className={styles.inputs__field}>
                    <input type="number" id='users' name="users" value={users} 
                        onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); }}}
                        onChange={(e) => onChangeHandler(e.target.name, e.target.value)} 
                    />
                </div>
                <h2>Users <BiInfoCircle className={styles.infoIcons} data-tip data-for="usersTip" tabIndex={-1} /></h2>
            </div>
            <div className={styles.inputs__input}>
                <h2 className={styles.inputIcons}>
                    <IoPeopleOutline />
                </h2>
                <div className={styles.inputs__field}>
                    <input type="number" id='calls' name="calls" value={calls} 
                        onKeyPress={(e) => { if (!/[0-9]/.test(e.key)) { e.preventDefault(); }}}
                        onChange={(e) => onChangeHandler(e.target.name, e.target.value)} 
                    />
                </div>
                <h2>Concurrent Calls <BiInfoCircle className={styles.infoIcons} data-tip data-for="callsTip" tabIndex={-1}/></h2>
            </div>
            <ReactTooltip id="usersTip" place="top" effect="solid">
                Calculation based on number of users
          </ReactTooltip>
          <ReactTooltip id="callsTip" place="top" effect="solid">
                Calculation based on number of concurrent calls for your user base
          </ReactTooltip>
        </section>
    );
};

export default Inputs;