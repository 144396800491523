import { useEffect, useState } from 'react';
import { IFormula } from '../interfaces/formula';
import axios from 'axios';
import styles from './Calculator.module.scss';
import Box from '../components/Box/Box';
import Inputs from '../components/Inputs/Inputs';

const App = () => { 
	// State to hold our formula data
	const [ formula, setFormula ] = useState<IFormula[]>();

	// State to hold the values from the users and call input
	const [ values, setValues ] = useState({
		users: 0,
		calls: 0
	});

	// This function handles setting the updated values
	// from the inputs, into a state
	const onChangeHandler = (target: string, value: string) => {
		if(value.length===10) return false;

		setValues((prev) => ({
			...prev,
			[target]: value
		}))
	}

	// This is a function to get the formula stored in a JSON file
	// We store the result from the call in our formula state
	const getFormula = () => {
		axios.get("/formula.json")
		.then((res: any) => setFormula(res.data))
		.catch((err: any) => console.log(err))
	};

	// We call our getFormula function within this useEffect function
	// This function only runs once, when the app first loads
	useEffect(() => {
		getFormula()
	}, [])

	return(
		<div>
			<div className={styles.mainArea}>				
				<Inputs 
					users={ values.users }
					calls={ values.calls } 
					onChangeHandler={ onChangeHandler }/>

				<div className={styles.boxes}>
					{
						formula?.map((item, index) => (
							<>
								<Box 
									domesticTotal={ values.users * formula[0].cost }
									showSaving={ item.showSaving }
									index={ index }
									subTitle={ item.subTitle || ""}
									key={ item.title }
									title={ item.title }
									perUser={ Math.round(((values[item.formula] * item.cost) / values.users) * 100) / 100 || 0 } 
									total={ values[item.formula] * item.cost || 0 }
									unitCost={ item.cost || 0 } 
									info={item.info} 
									moreText={item.moreText}
									moreLink={item.moreLink}
									formula={item.formula} />
							</>
						))
					}
				</div>
			</div>
		</div>
	);
};

export default App;