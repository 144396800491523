import styles from './Box.module.scss';

interface IProps { 
    domesticTotal: number;
    index: number;
    title: string;
    subTitle?: string;
    unitCost: number;
    total: number;
    perUser: number;
    showSaving: boolean;
    info: string;
    moreText: string;
    moreLink: string;
    formula: string;
}

const Box = ({ index, title, subTitle, unitCost, total, perUser, showSaving, domesticTotal, info, moreText, moreLink, formula }: IProps) => {
    const boxContainerClass = () => {
        if(index === 0) { 
            return [styles.box, styles["box--lightgrey"]].join(" ");
        } else if(index === 1) { 
            return [styles.box, styles["box--orange"]].join(" ");
        } else if (index === 2) { 
            return [styles.box, styles["box--orange"]].join(" ");
        } else if(index === 3) { 
            return [styles.box, styles["box--purple"]].join(" ");
        };
    };

    const calculatePercentage = () => {
        if(Number((((domesticTotal - total) / domesticTotal) * 100).toFixed(2)) < 0 || isNaN(Number((((domesticTotal - total) / domesticTotal) * 100).toFixed(2)))) {
            return 0
        } else { 
            return Number((((domesticTotal - total) / domesticTotal) * 100).toFixed(2))
        }
    };

    return(
        <section className={boxContainerClass()}>
            { 
                showSaving ? (
                    <div className={styles["box__has-saving"]}>
                        <div className={styles.box__inner}>
                            <h3 className={styles.box__text}>{ subTitle }</h3>
                            <div className={styles.box__stats}>
                                <div>
                                    <div className={styles.box__numberscenter}>£{ total.toLocaleString() }</div>
                                    <span className={styles.box__text}>Per Month</span>
                                </div>
                                <div className={styles["box__saving"]}>
                                    <div className={styles.box__numbers}>Save up to {calculatePercentage().toFixed(0)}%</div>
                                    <span className={styles.box__text}>Per Month*</span>
                                </div>
                                <div className={styles["box__info"]}>
                                    <div className={styles.infoText}>{info}</div>
                                    <a className={styles.roundedButton} href={moreLink} target="_blank">{moreText}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles["box__no-saving"]}>
                        <div className={styles.box__inner}>
                            <div>
                                <div className={styles.box__numbers}>
                                    {
                                        index !== 3 ? (
                                            <>£9</>
                                        ) : (
                                            "N/A"
                                        )
                                    }
                                </div>
                                <span>Per User / Month</span>
                            </div>
                        </div>
                        <div className={styles.box__stats}>
                            <ul>
                                <li>Cloud-based phone system</li>
                                <li>A domestic calling plan with 1,200 minutes</li>
                                <li>Calls from anywhere, on any device through the Microsoft Teams app</li>
                            </ul>
                        </div>
                    </div>
                )
            }
        </section>
    );
};

export default Box;